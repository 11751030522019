import { Box, Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { StockArticleEvent } from '../../../model';
import { WikusTypography } from '@wikus-ui/components/core/typography';
import { filter } from 'lodash';

const useStyles = makeStyles((theme) => ({
  highlight: {
    width: '4px',
    backgroundColor: 'white',
  },
  root: {
    width: '100%',
    maxWidth: '500px',
  },
  card: {
    padding: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  data: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  date: {
    fontSize: '8px',
    color: 'gray',
  },
  name: {
    fontSize: '18px',
    color: 'darkblue',
  },
  amounts: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  amountLabel: {
    fontSize: '8px',
    color: 'gray',
  },
  amountNumber: {
    fontSize: '18px',
    color: 'darkblue',
  },
}));

const getBorderColor = (eventName: string): string => ({
  OrderedChip: '#FFD568',
  OrderMailSent: '#FFD568',
  OrderMailToCustomerSent: '#FFD568',
  OrderMailCustomArticleSent: '#FFD568',
  ShippedChip: '#CDFFD2',
  ArticleItemArrivalConfirmed: '#CDFFD2',
  ArticleItemDeleted: '#FC7D7D',
  ArticleChangedItems: '#FFD568',
  ArticleItemChanged: '#FFD568',
  ArticleChanged: '#FFD568',
  ArticleAdded: '#CDFFD2',
  ArticleItemAdded: '#CDFFD2',
  ArticleItemTaken: '#FC7D7D',
})[eventName] || 'white';

export const ArticleEventCard = ({ event, showArticleId, children }: { event: StockArticleEvent, showArticleId?: boolean, children?: React.ReactNode }) => {
  const styles = useStyles();
  const intl = useIntl();

  const formattedEventName = event.eventName.replace('Event', '');
  const i18nName = intl.formatMessage({ id: `event.${formattedEventName}` });
  // If it falls back to the default, use the event's eventName
  const eventName = i18nName === `event.${formattedEventName}` ? formattedEventName : i18nName;

  const detailInfo = filter([
    event.user?.email,
    event.article?.name,
    event.machine?.name
  ]).join(' | ');

  return (
    <Box width='100%' className={styles.root} mb={3} borderLeft={6} borderRadius={4} borderColor={getBorderColor(formattedEventName)}>
      <Card className={styles.root}>
        <CardContent className={styles.card}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" flexDirection="column" justifyContent="center">
              <WikusTypography variant="caption">
                <FormattedDate value={event.createdAt} />
              </WikusTypography>
              <WikusTypography>
                {showArticleId ? event.article?.article?.itemNumber : eventName}
              </WikusTypography>
              {/* {event.childName && <div></div>} */}
              {/* <WikusTypography variant="subtitle1">
                {event.createdAt}
              </WikusTypography> */}
            </Box>

            <Box display="flex" justifyContent="flex-end" textAlign="right">
              {(event?.amount || 0) > 0 && (
                <Box flexDirection="column" ml={2}>
                  <WikusTypography variant="caption">
                    <FormattedMessage id="event.shippedAmount" />
                  </WikusTypography>
                  <WikusTypography>
                    {event.amount}
                  </WikusTypography>
                </Box>
              )}
              {(event?.total || 0) > 0 && (
                <Box flexDirection="column" ml={2}>
                  <WikusTypography variant="caption">
                    <FormattedMessage id="event.stockTotal" />
                  </WikusTypography>
                  <WikusTypography>
                    {event.total}
                  </WikusTypography>
                </Box>
              )}
            </Box>
          </Box>

          {!!detailInfo && <Grid container justifyContent="flex-start">
            <Box py={0}>
              <WikusTypography variant="caption">
                {detailInfo}
              </WikusTypography>
            </Box>
          </Grid>}

          {children && <Grid container justifyContent="flex-end">
            <Box py={2}>{children}</Box>
          </Grid>}
        </CardContent>
      </Card>
    </Box>
  );
};
